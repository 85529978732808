<template>
  <div
    className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip"
  >
    <!-- Site header -->
    <HeaderSection :display_lang="false" />

    <!-- Page content -->
    <main class="grow mb-8">
      <section>
        <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
          <!-- Particles animation -->
          <ParticlesComponent class="absolute inset-0 -z-10" />

          <!-- Illustration -->
          <div
            class="absolute inset-0 -z-10 -mx-28 rounded-3xl pointer-events-none overflow-hidden"
            aria-hidden="true"
          >
            <div class="absolute right-[40%] -translate-x-1/2 top-0 -z-10">
              <img
                src="@/assets/images/stellar/glow-top-bottom.svg"
                class="max-w-none w-[360px] h-[360px]"
                alt="Hero Illustration"
              />
            </div>
          </div>

          <div class="pt-10 pb-10 md:pt-20 md:pb-2">
            <div
              class="container mx-auto min-h-52 lg:flex lg:flex-col block lg:py-12 py-3 md:px-4 px-px"
            >
              <!-- Page header -->
              <div class="text-center md:mb-6 mb-3 max-md:mt-4">
                <h1
                  class="font-inter xl:text-[50px]/tight lg:text-5xl/tight md:text-3xl/tight text-xl/tight font-bold capitalize from-slate-50/60 via-slate-50 to-slate-50/60 mb-4 bg-clip-text text-transparent bg-gradient-to-r"
                  v-html="$t('blog_title')"
                ></h1>
              </div>
<!--overlay-->
               <div class="">
                <div v-if="is_loading"
                    class="  z-10 min-h-40 h-full w-full flex items-center justify-center">
                    <div class="flex items-center">
                        <!-- <span class="text-3xl mr-4">Loading</span> -->
                        <svg class="animate-spin h-8 w-8 text-gray-800 dark:text-white" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                    </div>
                </div>
              <!-- blog list -->
              <main
                class="my-8 mx-auto md:mx-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12" 
              >
                <div
                  class="flex mx-auto w-full"
                  v-for="(blog) in blog_list"
                  :key="blog.id"
                >
                  <router-link class="w-full max-w-full"
                    :to="{ name: 'BlogDetailView', params: { id: blog.id } }"
                  >
                    <div class=" overflow-hidden">
                      <img
                        class="w-full max-w-full h-48 object-cover rounded"
                        :src="blog.post_image"
                        :alt="blog.post_title"
                      />
                      <div class="mt-5">
                        <h2
                          class="font-inter lg:text-xl/normal md:text-lg/normal text-base/normal font-bold text-white mb-2"
                        >
                          {{ blog.post_title }}
                        </h2>
                        <!-- truncate the content to 150 characters -->
                        <div
                          class="font-inter md:text-base/normal text-sm font-normal text-grey-100"
                          v-html="truncateHtml(blog.post_content, 150)" 
                        ></div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </main>

              <!--pagination-->
              <div v-if="blog_list.length > 0"
                class="mt-8 mb-2 w-full flex flex-wrap h-full mx-0 justify-between px-4 pb-4"
              >
                <div class="md:w-1/2 w-full flex items-center md:mt-2 mt-5">
                  <p class="dataset-info max-md:text-center max-md:mx-auto">
                    {{ $t("table_show_entries_showing") }} {{ from_page }}
                    {{ $t("table_show_entries_to") }} {{ to_page }}
                    {{ $t("table_show_entries_of") }} {{ total_items }}
                    {{ $t("table_show_entries_entries") }}
                  </p>
                </div>
                <div class="md:w-1/2 w-full flex md:mt-2 mt-5">
                  <div
                    class="w-auto max-w-full inline-flex items-center md:float-right md:mr-0 mx-auto"
                  >
                    <vue-awesome-paginate
                      class="paginate-container float-right"
                      :total-items="total_items"
                      :items-per-page="per_page"
                      :max-pages-shown="3"
                      :show-ending-buttons="true"
                      v-model="currentPage"
                      :on-click="onClickHandler"
                    >
                      <template #prev-button>
                        <font-awesome-icon icon="fa-solid fa-angle-left" />
                      </template>
                      <template #next-button>
                        <font-awesome-icon icon="fa-solid fa-angle-right" />
                      </template>
                      <template #first-page-button>
                        <font-awesome-icon icon="fa-solid fa-angles-left" />
                      </template>
                      <template #last-page-button>
                        <font-awesome-icon icon="fa-solid fa-angles-right" />
                      </template>
                    </vue-awesome-paginate>
                  </div>
                </div>
              </div>
               </div> <!-- end overlay -->
            </div>
          </div>
        </div>
      </section>

      <!-- <SubscribeNewsletter /> -->
    </main>
    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from "@/partials/HeaderSection.vue";
import FooterSection from "@/partials/FooterSection.vue";
import ParticlesComponent from "@/partials/ParticlesComponent.vue";
// import SubscribeNewsletter from "@/partials/SubscribeNewsletter.vue";

import BlogService from '@/services/BlogService';

export default {
  name: "BlogListView",
  components: {
    HeaderSection,
    FooterSection,
    ParticlesComponent,
    // SubscribeNewsletter,
  },
  data() {
    return {
      is_loading: false,
      blog_list: [],
      per_page: 15,
      currentPage: 1,
      total_items: 0,
      from_page: 1,
      to_page: 1,
      meta: null,
    };
  },
  async mounted() {
    await this.getBlogListWithPagination();
  },
  watch: {
    currentPage: function() {
      this.getBlogListWithPagination();
    }
  },

  methods: {
 
      truncateHtml(html, maxLength) {
        if (!html) return '';
        
        // Create temporary element to parse HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        
        // Get plain text content
        const textContent = doc.body.textContent || '';
        
        // Truncate the text content
        const truncatedText = textContent.length > maxLength 
          ? textContent.substring(0, maxLength).trim() + ' ...'
          : textContent; 
        
        // preview element with basic formatting
        const previewContent = truncatedText
          .split('\n')
          .filter(line => line.trim())
          .map(line => `<p>${line}</p>`)
          .join('');
        
        return `<div class="blog-preview">${previewContent}</div>`;
      },
    async getBlogListWithPagination() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      
      this.is_loading = true;
      try {
        const response = await BlogService.getAllPosts(
          'en',//this.$i18n.locale, 
          this.per_page, 
          this.currentPage
        );

        this.blog_list = response.data.data;
        this.meta = response.data.meta;
        
        this.total_items = this.meta.total;
        this.from_page = this.meta.from;
        this.to_page = this.meta.to;
        this.currentPage = this.meta.current_page;
        
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      } finally {
        this.is_loading = false;
      }
    },
  },
};
</script>

<!-- <style scoped>
.blog-preview {
  @apply text-gray-600 dark:text-gray-300;
}

.blog-preview p {
  @apply mb-2;
}

.blog-preview p:last-child {
  @apply mb-0;
}
</style> -->