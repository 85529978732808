<template>
  <div
    className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip"
  >
    <!-- Site header -->
    <HeaderSection :display_lang="false"/>

    <!-- Page content -->
    <main class="grow mb-8">
      <section>
        <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
          <!-- Particles animation -->
          <ParticlesComponent class="absolute inset-0 -z-10" />

          <!-- Illustration -->
          <div
            class="absolute inset-0 -z-10 -mx-28 rounded-3xl pointer-events-none overflow-hidden"
            aria-hidden="true"
          >
            <div class="absolute right-[40%] -translate-x-1/2 top-0 -z-10">
              <img
                src="@/assets/images/stellar/glow-top-bottom.svg"
                class="max-w-none w-[360px] h-[360px]"
                alt="Hero Illustration"
              />
            </div>
          </div>

          <div class="pt-10 pb-10 md:pt-20 md:pb-2">
            <div
              class="w-full mx-auto min-h-52 lg:flex lg:flex-col block lg:py-12 py-3 md:px-4 px-px"
            >
              <div>
                <div
                  v-if="is_loading"
                  class="z-10 min-h-40 h-full w-full flex items-center justify-center"
                >
                  <div class="flex items-center">
                    <!-- <span class="text-3xl mr-4">Loading</span> -->
                    <svg
                      class="animate-spin h-8 w-8 text-gray-800 dark:text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <template v-if="blog_obj">
                  <!-- Page header -->
                  <div class="text-center md:mb-6 mb-3 max-md:mt-4">
                    <h1
                      class="font-inter xl:text-[50px]/tight lg:text-5xl/tight md:text-3xl/tight text-xl/tight font-bold capitalize from-slate-50/60 via-slate-50 to-slate-50/60 mb-4 bg-clip-text text-transparent bg-gradient-to-r"
                      v-html="
                        blog_obj[`title_${$i18n.locale}`] || blog_obj.title_en
                      "
                    ></h1>
                  </div>

                  <!-- blog  -->
                  <section class="my-8 mx-auto lg:w-9/12 md:w-10/12 w-11/12">
                    <img
                      :src="blog_obj.img"
                      class="w-full mx-auto max-h-96 object-cover mb-8"
                    />

                    <div class="blog-content"
                      v-html="
                        blog_obj[`content_${$i18n.locale}`] ||
                        blog_obj.content_en
                      "
                    ></div>

                    <router-link
                      :to="{ name: 'BlogListView' }"
                      class=" block mt-10 text-base/normal font-medium no-underline text-primary hover:text-primary-500 hover:underline"
                    >
                      <font-awesome-icon icon="fa-solid fa-arrow-left" />
                      {{ $t("back_blog_link") }}
                    </router-link>
                  </section>
                </template>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from "@/partials/HeaderSection.vue";
import FooterSection from "@/partials/FooterSection.vue";
import ParticlesComponent from "@/partials/ParticlesComponent.vue";
import BlogService from '@/services/BlogService';


export default {
  name: "BlogPostView",
  components: {
    HeaderSection,
    FooterSection,
    ParticlesComponent,
  },
  data() {
    return {
      is_loading: false,

      id: this.$route.params.id,

      blog_obj: null,
    };
  },
  mounted() {
    this.getBlogPost(this.id);
  },
  methods: {
    async getBlogPost(id) {
      this.is_loading = true;
      try {
        const response = await BlogService.getPostById(id);
        const post = response.data[0]; // Since the API returns an array
        this.blog_obj = {
          id: post.id,
          img: post.post_image,
          title_en: post.post_title,
          content_en: post.post_content
        };
      } catch (error) {
        console.error('Error fetching blog post:', error);
        
      } finally {
        this.is_loading = false;
      }
    },
  },
};
</script>

<style>

.blog-content, .blog-content p {
  @apply text-lg/snug font-normal text-grey-protostars-100;
}
.blog-content p{
  @apply mb-2;
}
.blog-content p a{
  @apply underline;
}
.blog-content > a{
  @apply text-[#0070f4];
}
.blog-content  h1{
    @apply text-4xl/normal font-bold text-white;
}
.blog-content  h2{
    @apply text-3xl/normal font-bold text-white;
}
.blog-content  h3{
    @apply text-2xl/normal font-bold text-grey-protostars-100;
}
.blog-content  h4{
    @apply text-xl/normal font-bold text-grey-protostars-100;
}
.blog-content  h5{
    @apply text-lg/normal font-bold text-grey-protostars-100;
}
.blog-content  h6{
    @apply text-base/normal font-bold text-grey-protostars-100;
}
.blog-content  ul{
    @apply list-disc list-inside;
}
.blog-content  ol{
    @apply list-decimal list-inside;
}
.blog-content  blockquote{
    @apply border-l-4 border-primary-500 pl-4 my-4;
}
.blog-content  pre{
    @apply bg-grey-protostars-100 p-4 my-4;
}
.blog-content  code{
    @apply bg-grey-protostars-100 p-1;
}
.blog-content  img{
    @apply w-full mx-auto my-4;
}

</style>
