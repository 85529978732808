import axios from "axios";

export default {
  /**
   * Fetch all blog posts
   * @param {string} lang - Language code (e.g., 'en', 'fr')
   * @param {number} perPage - Number of items per page
   * @param {number} page - Page number
   * @returns {Promise} Promise object represents the blog posts with pagination metadata
   */
  getAllPosts(lang, perPage = 15, page = 1) {
    return axios.get("/blog/posts", {
      params: {
        lang,
        per_page: perPage,
        page,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },

  /**
   * Fetch a single blog post by ID
   * @param {number|string} id - The ID of the blog post
   * @returns {Promise} Promise object represents the blog post
   */
  getPostById(id) {
    return axios.get(`/blog/posts/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
};
